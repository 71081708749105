import { ContactForm, Layout } from "components/CaseLayout";
import SEO from "components/seo";
import React from "react";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";
import { PPWB } from "shared/Types/cases";
import { ContentPhoto, Header, Hero, TextBox, WhatWeDid } from "../../components/CaseLayout";
import { ContactBanner } from "../../components/CaseLayout/ContactBanner/contactBanner";

const RowerowaPrzygodaPage = () => {
  const data = useCaseData(PPWB);

  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero data={data.hero} detailWidth={340}/>
      <WhatWeDid data={data.what_we_did}/>
      <ContactBanner data={data.contact_banners.first}/>
      <Header data={data.headers.first} paddingBottom={1} align="left"/>
      <ContentPhoto content={1400} data={data.content_images.first}/>
      <TextBox data={data.text_boxes.first}/>
      <ContentPhoto content={1400} data={data.content_images.second}/>
      <TextBox data={data.text_boxes.second} sticky/>
      <ContentPhoto content={1400} data={data.content_images.third}/>
      <ContactBanner data={data.contact_banners.second}/>
      <TextBox data={data.text_boxes.third} sticky/>
      <ContentPhoto content={1400} data={data.content_images.fourth}/>
      <TextBox data={data.text_boxes.fourth} sticky/>
      <ContentPhoto content={1400} data={data.content_images.fifth}/>
      <TextBox data={data.text_boxes.fifth}/>
      <ContactBanner data={data.contact_banners.third}/>
      <TextBox data={data.text_boxes.sixth}/>
      <ContactForm/>
    </Layout>
  );
};

export default RowerowaPrzygodaPage;
