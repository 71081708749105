import styled from "styled-components";
import { SectionWrapper } from "../../../shared/Styles/Wrappers";

export const Container = styled.section`
    background-color: ${({ background }) => (background ? background : "#F4F4F4")};
    color: ${({ color }) => (color ? color : "#000")};
    padding: 60px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        padding: 48px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding: 36px 0;
    }
`;

export const Content = styled(SectionWrapper)`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        flex-direction: column;
        text-align: center;
    }
`;

export const Header = styled.header`
    margin: 0 auto 0 0;
    max-width: 820px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        margin: 0 auto 24px;
    }
`;

export const Icon = styled.div`
    --icon-width: 86px;

    margin-right: 48px;
    width: var(--icon-width);
    height: calc(var(--icon-width) * 0.79);
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        --icon-width: 64px;

        margin-right: 36px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        --icon-width: 48px;

        margin-right: 24px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        margin-right: 0;
        margin-bottom: 12px;
    }
`;

export const Title = styled.h2`
    margin-bottom: 16px;
    font-size: 3.2rem;
    font-weight: 800;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: 3rem;
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        font-size: 2.8rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 2.4rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
        font-size: 1.8rem;
    }
`;

export const Text = styled.p`
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.5;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 1.2rem;
    }
`;
