import React from "react";
import { Container, Content, Header, Icon, Text, Title } from "./styles";
import { Button } from "../../Buttons";

export const ContactBanner = ({ data, background, color }) => {
  return (
    <Container backgroung={background} color={color}>
      <Content>
        {data.icon && (
          <Icon>
            <img src={data.icon} alt=""/>
          </Icon>
        )}
        <Header>
          {data.title && <Title>{data.title}</Title>}
          {data.text && <Text>{data.text}</Text>}
        </Header>
        <Button anchor="#contact-form">{data.button}</Button>
      </Content>
    </Container>
  )
}
